import { useTranslation } from "react-i18next"
import { useAccount, useMsal } from "@azure/msal-react"
import { useCallback, useEffect, useState } from "react"
import { ToggleChip } from "@s-group/design-system-components"
import { FullProduct } from "./FullProduct"
import { AxiosError } from "axios"
import { useParams } from "react-router-dom"
import { isValidSokIdOrEan, ProductSearchInput } from "./ProductSearchInput"
import { GenericSearchResult } from "../domain/GenericSearchResult"
import { LoadingAnimation } from "./LoadingAnimation"
import { RetailProductApiSearchErrorMessage } from "./RetailProductApiSearchErrorMessage"
import {
    ERROR_SESSION_EXPIRED,
    getAccessToken,
    getAllBySokIdAndEan,
    getAttributeDetails,
    handleSearchError,
    QUERY_PARAM_SEARCH,
    queryWithReload,
    searchFromRetailProductApiBySokIdUsingGet,
    SokIdUrlParam,
    useSearchParams
} from "../utils/SearchFormUtil"
import { PreviousSearchList } from "./PreviousSearchList"
import { handleSetSearchToLocalStorage } from "../utils/LocalStorageUtil"
import MultipleResults from "./MultipleResults"
import { MultipleSearchResults } from "../domain/MultipleSearchResults"

export const RetailProductApiSearchForm = () => {
    const { t } = useTranslation()
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})
    const urlParams = useParams<SokIdUrlParam>()
    const searchParams = useSearchParams()
    const [searchResult, setSearchResult] = useState<GenericSearchResult>({
        inProgress: false
    })
    const [multipleSearchResults, setMultipleSearchResults] = useState<MultipleSearchResults | null>(null)
    const [attributeDetails, setAttributeDetails] = useState<Map<any, any>>()
    const [accessToken, setAccessToken] = useState("")
    const [showNullFields, setShowNullFields] = useState(false)
    const [showJsonFieldNames, setShowJsonFieldNames] = useState(false)
    const [openAllAccordions, setOpenAllAccordions] = useState(false)

    const handleProductSearchError = (error: Error | AxiosError) => {
        handleSearchError(error, (res) => setSearchResult(res))
    }

    const searchProductBySokId = useCallback((sokId: string, token: string) => {
        searchFromRetailProductApiBySokIdUsingGet("fullProducts/" + sokId, sokId, token, "/product-api/" + sokId)
            .then((res) => {
                if (res != null) {
                    setSearchResult(res)
                    handleSetSearchToLocalStorage(res, res.data.ean, sokId)
                }
            })
            .catch(handleProductSearchError)
    }, [])

    const search = useCallback(
        (searchTerm: string, token: string) => {
            if (!searchTerm || !isValidSokIdOrEan(searchTerm)) {
                return
            }
            setSearchResult({ inProgress: true })
            setMultipleSearchResults(null)
            searchTerm = searchTerm.trim()

            getAllBySokIdAndEan(searchTerm, token, "/product-api/")
                .then((result) => {
                    const numberOfResults = result.getNumberOfResults()
                    if (numberOfResults === 0) {
                        setSearchResult({ inProgress: false, statusCode: 404 })
                    } else if (numberOfResults === 1) {
                        const searchResult = result.getOneResultOrNull()
                        if (searchResult !== null) {
                            searchProductBySokId(searchResult.sokId!, token)
                        }
                    } else {
                        setMultipleSearchResults(result)
                        setSearchResult({ inProgress: false })
                    }
                })
                .catch((e) => {
                    console.error(e)
                    if (e === ERROR_SESSION_EXPIRED) {
                        queryWithReload("/product-api/", searchTerm)
                    } else {
                        handleSearchError(e, (res) => setSearchResult(res))
                    }
                })
        },
        [searchProductBySokId]
    )

    useEffect(() => {
        getAccessToken(account, instance).then((token) => {
            setAccessToken(token)
            getAttributeDetails(token).then((map) => setAttributeDetails(map))
            const q = urlParams.sokId || searchParams.get(QUERY_PARAM_SEARCH)
            if (q != null) {
                search(q, token)
            }
        })
    }, [instance, account, urlParams, searchParams, search])

    return (
        <div>
            <h1>{t("RetailProductApi.title")}</h1>
            <p>{t("RetailProductApi.helpText")}</p>
            <PreviousSearchList site={"/product-api/"} />
            <ProductSearchInput
                initialValue={(urlParams && urlParams.sokId) || searchParams.get(QUERY_PARAM_SEARCH) || ""}
                onSearch={(value) => {
                    search(value, accessToken)
                }}
                onKeyPress={(event) => {
                    if (event.key === "Enter") {
                        const target: any = event.target
                        const text: string = target.value
                        search(text, accessToken)
                    }
                }}
            />
            <div>
                <ToggleChip
                    className={"search-option-switch"}
                    checked={showNullFields}
                    showCheckmark={false}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setShowNullFields(event.currentTarget.checked)
                    }}
                >
                    {t("RetailProductApi.showNullFields")}
                </ToggleChip>
                <ToggleChip
                    className={"search-option-switch"}
                    checked={showJsonFieldNames}
                    showCheckmark={false}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setShowJsonFieldNames(event.currentTarget.checked)
                    }}
                >
                    {t("RetailProductApi.showJsonFieldNames")}
                </ToggleChip>
                <ToggleChip
                    className={"search-option-switch"}
                    checked={openAllAccordions}
                    showCheckmark={false}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setOpenAllAccordions(event.currentTarget.checked)
                    }}
                >
                    {t("RetailProductApi.openAllAccordions")}
                </ToggleChip>
            </div>

            <br />
            <br />
            {searchResult.inProgress && <LoadingAnimation />}

            {multipleSearchResults ? <MultipleResults results={multipleSearchResults} site={"/product-api/"} /> : null}

            {searchResult.statusCode && searchResult.statusCode !== 200 ? (
                <RetailProductApiSearchErrorMessage searchResult={searchResult} />
            ) : (
                <FullProduct
                    source={"RETAIL_PRODUCT_API"}
                    result={searchResult.data}
                    showNullFields={showNullFields}
                    showJsonFieldNames={showJsonFieldNames}
                    openAllAccordions={openAllAccordions}
                    attributeDetails={attributeDetails}
                />
            )}
        </div>
    )
}
