import React from "react"
import { Button } from "@s-group/design-system-components"
import "./HeadingNavigator.css"
import arrowUpIcon from "../assets/arrow_up.svg"
import arrowDownIcon from "../assets/arrow_down.svg"
import arrowUpToBarIcon from "../assets/arrow_up_to_bar.svg"
import arrowDownToBarIcon from "../assets/arrow_down_to_bar.svg"
import { KEY_HEADING_NAVIGATOR_DISABLED } from "../pages/Profile"

export const HeadingNavigator: React.FC = () => {
    const scrollToClosestHeading = (direction: "prev" | "next") => {
        const navbarHeight = 80 // Adjust this value according to the height of your navigation bar
        const currentScroll = window.scrollY + (direction === "prev" ? -navbarHeight : navbarHeight)

        const headings = Array.from(document.querySelectorAll("h1, h2, h3, h4, h5, h6")) as HTMLElement[]
        let closestHeadingIndex = -1
        let closestHeadingDistance = Number.MAX_SAFE_INTEGER

        headings.forEach((heading, index) => {
            const headingDistance = heading.offsetTop - currentScroll
            if (direction === "prev" ? headingDistance < 0 : headingDistance > 0) {
                const absDistance = Math.abs(headingDistance)
                if (absDistance < closestHeadingDistance) {
                    closestHeadingIndex = index
                    closestHeadingDistance = absDistance
                }
            }
        })

        if (closestHeadingIndex !== -1) {
            const closestHeading = headings[closestHeadingIndex]
            const scrollPosition = closestHeading.offsetTop - navbarHeight
            window.scrollTo({
                top: scrollPosition,
                behavior: "smooth"
            })
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth"
        })
    }

    if (localStorage.getItem(KEY_HEADING_NAVIGATOR_DISABLED) === "true") {
        return null
    }

    return (
        <div id={"heading-navigator"}>
            <Button variant="tonal" compact={true} onClick={scrollToTop}>
                <img src={arrowUpToBarIcon} alt={"Go to the top"} />
            </Button>
            <Button variant="tonal" compact={true} onClick={() => scrollToClosestHeading("prev")}>
                <img src={arrowUpIcon} alt={"Go to the previous heading"} />
            </Button>
            <Button variant="tonal" compact={true} onClick={() => scrollToClosestHeading("next")}>
                <img src={arrowDownIcon} alt={"Go to the next heading"} />
            </Button>
            <Button variant="tonal" compact={true} onClick={scrollToBottom}>
                <img src={arrowDownToBarIcon} alt={"Go to the bottom"} />
            </Button>
        </div>
    )
}
