import { useTranslation } from "react-i18next"
import { BonusBuyUtils } from "./BonusBuyUtils"
import { ScenarioTable } from "./ScenarioTable"
import { formatTimestamp } from "../../utils/DateUtils"

interface PercentageDiscountTableProps {
    data: any
    sitesMap: any
}

export const PercentageDiscountsTable = (props: PercentageDiscountTableProps) => {
    const { t } = useTranslation()
    const { data } = props

    if (!data || data.length === 0) {
        return null
    }

    return data.map((item: any, index: number) => {
        if (item["sourceDeletedAt"] !== null) {
            return null
        }

        const discountMap = new Map<string, string | undefined>()
        discountMap.set(t("PercentageDiscounts.promotionId"), item["promotionId"])
        discountMap.set(t("PercentageDiscounts.customerType"), item["discountDetails"]["customerType"])
        discountMap.set(t("Timestamps.sourceModifiedAt"), formatTimestamp(item["sourceModifiedAt"]))
        discountMap.set(t("Timestamps.modifiedAt"), formatTimestamp(item["modifiedAt"]))
        discountMap.set(t("PercentageDiscounts.validFrom"), item["discountDetails"]["validFrom"])
        discountMap.set(t("PercentageDiscounts.validTo"), item["discountDetails"]["validTo"])
        discountMap.set(
            t("PercentageDiscounts.sites"),
            BonusBuyUtils.getSiteIdsString(item["discountDetails"]["siteIds"], props.sitesMap, "\n")
        )

        const discountDetails = item["discountDetails"]["receiveDetails"]
        discountDetails.forEach((receiveItem: any, receiveIndex: number) => {
            const index = receiveIndex + 1
            discountMap.set(
                t("PercentageDiscounts.discount") + BonusBuyUtils.getReceiveItemIndex(index, discountDetails.length),
                `${receiveItem["discount"]} ${receiveItem["discountUnit"]}`
            )
            discountMap.set(
                t("PercentageDiscounts.discountItems") +
                    BonusBuyUtils.getReceiveItemIndex(index, discountDetails.length),
                BonusBuyUtils.getItemsString(receiveItem["items"], "\n")
            )
            discountMap.set(
                t("PercentageDiscounts.materialGroups") +
                    BonusBuyUtils.getReceiveItemIndex(index, discountDetails.length),
                BonusBuyUtils.getMaterialGroupsString(receiveItem["materialGroups"], "\n")
            )
            discountMap.set(
                t("PercentageDiscounts.brands") + BonusBuyUtils.getReceiveItemIndex(index, discountDetails.length),
                BonusBuyUtils.getBrandsString(receiveItem["brands"], "\n")
            )
        })

        return ScenarioTable({
            bonusBuysMap: discountMap,
            tableHeader: null,
            id: "PercentageDiscountTable-" + index
        })
    })
}
