import ProfileButton from "./ProfileButton"
import SignInSignOutButton from "./SignInSignOutButton"
import { Link, useHistory } from "react-router-dom"

import "./NavBar.css"
import { Button } from "@s-group/design-system-components"
import { useTranslation } from "react-i18next"
import { useMsal } from "@azure/msal-react"
import { useEffect, useState, MouseEvent } from "react"
import { environmentName } from "../utils/EnvironmentUtil"

const NavBar = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const { instance } = useMsal()
    const account = instance.getActiveAccount()
    const [name, setName] = useState("")

    const navigateToPageUsingSokIdParameterIfItExists = (path: string) => {
        const latestSokId = sessionStorage.getItem("latestSokId")
        const param = latestSokId ? "/" + latestSokId : ""
        history.push(path + param)
    }

    useEffect(() => {
        if (account && account.name) {
            setName(account.name)
        }
    }, [instance, account])

    return (
        <div className="navbar">
            <div className={"width-limit nav-content"}>
                <div className={"nav-links-container"}>
                    <Link to="/" className="logo" onClick={() => sessionStorage.removeItem("latestSokId")}>
                        AAPA Browser {environmentName}
                    </Link>
                    {name && (
                        <>
                            <Button
                                variant={"plain"}
                                color={"neutral"}
                                sizing={"small"}
                                onClick={(event: MouseEvent<HTMLElement>) => {
                                    navigateToPageUsingSokIdParameterIfItExists("/product-api")
                                    event.currentTarget.blur()
                                }}
                            >
                                {t("RetailProductApi.title")}
                            </Button>
                            <Button
                                variant={"plain"}
                                color={"neutral"}
                                sizing={"small"}
                                onClick={(event: MouseEvent<HTMLElement>) => {
                                    navigateToPageUsingSokIdParameterIfItExists("/stock-quantities")
                                    event.currentTarget.blur()
                                }}
                            >
                                {t("StockQuantities.title")}
                            </Button>
                            <Button
                                variant={"plain"}
                                color={"neutral"}
                                sizing={"small"}
                                onClick={(event: MouseEvent<HTMLElement>) => {
                                    navigateToPageUsingSokIdParameterIfItExists("/assortments")
                                    event.currentTarget.blur()
                                }}
                            >
                                {t("Assortments.title")}
                            </Button>
                            <Button
                                variant={"plain"}
                                color={"neutral"}
                                sizing={"small"}
                                onClick={(event: MouseEvent<HTMLElement>) => {
                                    navigateToPageUsingSokIdParameterIfItExists("/prices")
                                    event.currentTarget.blur()
                                }}
                            >
                                {t("Prices.title")}
                            </Button>
                            <Button
                                variant={"plain"}
                                color={"neutral"}
                                sizing={"small"}
                                onClick={(event: MouseEvent<HTMLElement>) => {
                                    history.push("/sites")
                                    event.currentTarget.blur()
                                }}
                            >
                                {t("Sites.title")}
                            </Button>
                            <Button
                                data-testid={"ecomWarehouses-link"}
                                variant={"plain"}
                                color={"neutral"}
                                sizing={"small"}
                                onClick={(event: MouseEvent<HTMLElement>) => {
                                    history.push("/ecom-warehouses")
                                    event.currentTarget.blur()
                                }}
                            >
                                {t("EcomWarehouses.title")}
                            </Button>
                        </>
                    )}
                    <div className={"profile"}>
                        <ProfileButton />
                        <SignInSignOutButton />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavBar
