import React, { useState } from "react"
import { Button, HelperText, TextInput } from "@s-group/design-system-components"
import { useTranslation } from "react-i18next"
import { IconControlSearch, IconStatusMissingFilled } from "@s-group/design-system-icons"

interface ProductSearchInputProps {
    initialValue?: string
    onSearch: (value: string) => void
    onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

export const REGEXP_NUMBERS_ONLY = /^(EP)?\d*$/gm

export const isValidSokIdOrEan = (value: string) => value.match(REGEXP_NUMBERS_ONLY) !== null

export const ProductSearchInput = (props: ProductSearchInputProps) => {
    const { t } = useTranslation()
    const [value, setValue] = useState(props.initialValue)
    const [valid, setValid] = useState(true)

    return (
        <div className={"product-search-input-container"}>
            <TextInput
                className={"product-search-input"}
                value={value}
                onKeyPress={props.onKeyPress}
                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                    const v = event.currentTarget.value
                    setValid(isValidSokIdOrEan(v))
                    setValue(v)
                }}
            />
            <Button
                className={"clear-search-input-button"}
                icon={<IconStatusMissingFilled />}
                sizing={"small"}
                variant={"plain"}
                color={"neutral"}
                onClick={() => {
                    setValue("")
                    setValid(true)
                }}
            />
            <Button
                icon={<IconControlSearch />}
                sizing={"small"}
                variant={"filled"}
                color={"primary"}
                onClick={() => {
                    if (value != null && value.length > 0) {
                        props.onSearch(value)
                    }
                }}
            />
            {!valid && (
                <HelperText className={"invalid-product-id"} error={true} sizing={"small"}>
                    {t("Common.inputNumbersOnly")}
                </HelperText>
            )}
        </div>
    )
}
