import { useTranslation } from "react-i18next"
import { Site } from "../utils/SitesApi"
import { formatTimestamp } from "../utils/DateUtils"

export interface StockQuantitiesTableProps {
    quantityMap: any
    sitesMap: Map<string, Site>
}

export const StockQuantitiesTable = (props: StockQuantitiesTableProps) => {
    const { t } = useTranslation()
    const quantityMap = props.quantityMap

    return (
        <table>
            <thead>
                <tr>
                    <th>{t("Common.id")}</th>
                    <th>{t("Common.name")}</th>
                    <th>{t("StockQuantities.sourceStockQuantity")}</th>
                    <th>{t("StockQuantities.sourceAvailableQuantity")}</th>
                    <th>{t("StockQuantities.sourceTimestamp")}</th>
                    <th>{t("StockQuantities.timestampImported")}</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(quantityMap.locationIdToLocationQuantity).map((key: string) => {
                    const site = props.sitesMap.get(key)
                    const siteName = site ? site.siteName : ""
                    const qty = quantityMap.locationIdToLocationQuantity[key]
                    return (
                        <tr key={"balance-" + key}>
                            <td>{key}</td>
                            <td>{siteName}</td>
                            <td>{qty.sourceStockQuantity.quantity}</td>
                            <td>{qty.sourceAvailableQuantity.quantity}</td>
                            <td>{formatTimestamp(qty.sourceAvailableQuantity.timestamp)}</td>
                            {"timestampImported" in qty ? <td>{formatTimestamp(qty.timestampImported)}</td> : <td></td>}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}
