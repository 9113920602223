import { useTranslation } from "react-i18next"
import { Location, PromotionMatcher } from "../utils/LocationsApi"
import { useState } from "react"

interface EcomWareHousesTableProps {
    locations: Location[]
}

type LocationTableFormat = Omit<
    Location,
    | "ecom"
    | "clickAndCollect"
    | "promotionMatchers"
    | "validityIntervals"
    | "primarySupplierRestriction"
    | "ormaIds"
    | "allowedSalesChannels"
> & {
    ecom: string
    clickAndCollect: string
    promotionMatchers: string
    validityIntervals: string
    primarySupplierRestriction: string
    ormaIds: string
    allowedSalesChannels: string
}

export const EcomWarehousesTable = (props: EcomWareHousesTableProps) => {
    const { t } = useTranslation()
    const [sortByField, setSortByField] = useState<keyof Location>("locationId")
    const constructPromotionMatcher = (promotionMatchers?: PromotionMatcher[]) => {
        if (promotionMatchers === undefined) {
            return ""
        }

        const promotionMatcherPresentations: string[] = promotionMatchers.map((promotionMatcher) => {
            return `${promotionMatcher.typeId}, ${promotionMatcher.themeId}, ${promotionMatcher.chain}`
        })

        return promotionMatcherPresentations.join("\n")
    }

    const getFormattedAndOrderedLocations = (): LocationTableFormat[] => {
        const locationsInTableFormat: LocationTableFormat[] = props.locations.map((location) => {
            return {
                ...location,
                ecom: location.ecom.toString(),
                clickAndCollect: location.clickAndCollect.toString(),
                promotionMatchers: constructPromotionMatcher(location.promotionMatchers),
                validityIntervals: location.validityIntervals.map((interval) => interval.replace("/", "–")).join("\n"),
                primarySupplierRestriction: location.primarySupplierRestriction?.toString() ?? "",
                ormaIds: location.ormaIds?.join("\n") ?? "",
                allowedSalesChannels: location.allowedSalesChannels?.join("\n") ?? ""
            }
        })
        return [...locationsInTableFormat].sort((a: LocationTableFormat, b: LocationTableFormat) => {
            return compareLocationsByField(a, b, sortByField)
        })
    }

    const compareLocationsByField = (a: LocationTableFormat, b: LocationTableFormat, sortBy: keyof Location) => {
        const aField = a[sortBy]
        if (aField === "") {
            return 1
        }

        const bField = b[sortBy]
        if (bField === "") {
            return -1
        }

        return a[sortBy].localeCompare(b[sortBy])
    }
    const getTranslationOfLocationType = (locationType: string): string => {
        const translatedLocation: string | undefined = locationTypeMapping[locationType]

        const isUnknownLocationType = translatedLocation === undefined
        if (isUnknownLocationType) {
            return locationType
        }

        return translatedLocation
    }

    const locationTypeMapping: { [key: string]: string } = {
        DirectSupplierWarehouse: t("EcomWarehousesTable.directSupplierWarehouse"),
        Warehouse: t("EcomWarehousesTable.warehouse"),
        Store: t("EcomWarehousesTable.store")
    }

    const formattedAndOrderedLocations = getFormattedAndOrderedLocations()

    return (
        <table>
            <thead>
                <tr>
                    <th onClick={() => setSortByField("locationId")} data-testid={"locationId-header"}>
                        {t("EcomWarehousesTable.locationId")}
                    </th>
                    <th onClick={() => setSortByField("locationType")} data-testid={"locationType-header"}>
                        {t("EcomWarehousesTable.locationType")}
                    </th>
                    <th onClick={() => setSortByField("description")} data-testid={"description-header"}>
                        {t("EcomWarehousesTable.description")}
                    </th>
                    <th
                        onClick={() => setSortByField("promotionMatchers")}
                        data-testid={"promotionMatchers-header"}
                        style={{ whiteSpace: "pre" }}
                    >
                        {t("EcomWarehousesTable.promotionMatchers")}
                    </th>
                    <th onClick={() => setSortByField("ecom")} data-testid={"ecom-header"}>
                        {t("EcomWarehousesTable.ecom")}
                    </th>
                    <th onClick={() => setSortByField("clickAndCollect")} data-testid={"clickAndCollect-header"}>
                        {t("EcomWarehousesTable.clickAndCollect")}
                    </th>
                    <th onClick={() => setSortByField("validityIntervals")} data-testid={"validityIntervals-header"}>
                        {t("EcomWarehousesTable.validityIntervals")}
                    </th>
                    <th
                        onClick={() => setSortByField("allowedSalesChannels")}
                        data-testid={"allowedSalesChannels-header"}
                    >
                        {t("EcomWarehousesTable.allowedSalesChannels")}
                    </th>
                    <th
                        onClick={() => setSortByField("primarySupplierRestriction")}
                        data-testid={"primarySupplierRestriction-header"}
                    >
                        {t("EcomWarehousesTable.primarySupplierRestriction")}
                    </th>
                    <th onClick={() => setSortByField("ormaIds")} data-testid={"ormaIds-header"}>
                        {t("EcomWarehousesTable.ormaIds")}
                    </th>
                </tr>
            </thead>
            <tbody>
                {formattedAndOrderedLocations.map((location) => {
                    return (
                        <tr key={location.locationId}>
                            <td data-testid={`locationId-${location.locationId}`}>{location.locationId}</td>
                            <td data-testid={`locationType-${location.locationId}`}>
                                {getTranslationOfLocationType(location.locationType)}
                            </td>
                            <td data-testid={`description-${location.locationId}`}>{location.description}</td>
                            <td
                                style={{ whiteSpace: "pre-wrap" }}
                                data-testid={`promotionMatchers-${location.locationId}`}
                            >
                                {location.promotionMatchers.length !== 0 && (
                                    <ul>
                                        {location.promotionMatchers.split("\n").map((matcher, index) => (
                                            <li key={index}>{matcher}</li>
                                        ))}
                                    </ul>
                                )}
                            </td>
                            <td data-testid={`ecom-${location.locationId}`}>{location.ecom}</td>
                            <td data-testid={`clickAndCollect-${location.locationId}`}>{location.clickAndCollect}</td>
                            <td
                                style={{ whiteSpace: "pre-wrap" }}
                                data-testid={`validityIntervals-${location.locationId}`}
                            >
                                <ul>
                                    {location.validityIntervals.split("\n").map((interval, index) => (
                                        <li key={index}>{interval}</li>
                                    ))}
                                </ul>
                            </td>
                            <td
                                style={{ whiteSpace: "pre-wrap" }}
                                data-testid={`allowedSalesChannels-${location.locationId}`}
                            >
                                {location.allowedSalesChannels}
                            </td>
                            <td data-testid={`primarySupplierRestriction-${location.locationId}`}>
                                {location.primarySupplierRestriction}
                            </td>
                            <td style={{ whiteSpace: "pre-wrap" }} data-testid={`ormaIds-${location.locationId}`}>
                                {location.ormaIds}
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}
