import i18n from "i18next"
import translationEn from "./translations/en.json"
import translationFi from "./translations/fi.json"
import { initReactI18next } from "react-i18next"

export const resources = {
    en: {
        translation: translationEn
    },
    fi: {
        translation: translationFi
    }
} as const

const savedLanguage = localStorage.getItem("language") || "fi"
const defaultLanguage = ["fi", "en"].indexOf(savedLanguage) > -1 ? savedLanguage : "fi"

i18n.use(initReactI18next).init({
    lng: defaultLanguage,
    resources
})
