import { BrowserCacheLocation, Configuration, PopupRequest } from "@azure/msal-browser"
import { AxiosRequestConfig } from "axios"

const testToken = process.env.REACT_APP_TEST_ACCESS_TOKEN

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID || "",
        authority: "https://login.microsoftonline.com/a652adc3-7bb3-4312-8eb0-6ab323f7d6cd",
        redirectUri: window.location.protocol + "//" + window.location.host
        //postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage
    }
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["User.Read"]
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
}

export const getAxiosConfig = (accessToken: string): AxiosRequestConfig<never> => {
    return {
        timeout: 16000,
        headers: {
            Authorization: "Bearer " + (testToken ? testToken : accessToken),
            "Content-Type": "application/json"
            //"Referrer-Policy": "origin"
        },
        validateStatus: function (status) {
            // Only consider statusCodes > 500 as actual errors
            return status < 500
        }
    }
}

export const appScope = process.env.REACT_APP_AZURE_APP_SCOPE || ""
export const backendUrl = process.env.REACT_APP_BACKEND_URL || ""

export const appRoles = {
    ExtendedUser: "AapaBrowser.Extended"
}
