import { useState } from "react"
import { useTranslation } from "react-i18next"
import { SearchObject, getPreviousSearches } from "../utils/LocalStorageUtil"

interface PreviousSearchListProps {
    site: string
}

export const PreviousSearchList = (props: PreviousSearchListProps) => {
    const { t } = useTranslation()
    const [previousSearchTerms, setPreviousSearchTerms] = useState(getPreviousSearches())

    const handleStorageChange = () => {
        setPreviousSearchTerms(getPreviousSearches())
    }

    window.addEventListener("storage", handleStorageChange)

    return (
        <>
            {previousSearchTerms.length > 0 && (
                <div className={"previous-searches-container"}>
                    <span>{t("Common.previousSearches")}</span>
                    {previousSearchTerms.map((item: SearchObject, index: number) => (
                        <span key={index}>
                            <a href={`${props.site}${item.sokId}`}>
                                {item.sokId}
                                {item.ean !== null && ` (${item.ean})`}
                            </a>
                            {index < previousSearchTerms.length - 1 && " | "}
                        </span>
                    ))}
                </div>
            )}
        </>
    )
}
