import { DepositList } from "./DepositList"
import { siteArrayToMap } from "../utils/SitesApi"
import { useTranslation } from "react-i18next"
import { getNonEmptyColumns } from "../utils/SearchFormUtil"
import { allPricingUnitsEqual1, StoreRetailPricesTableProps } from "./StoreRetailPricesTable"
import { formatTimestamp } from "../utils/DateUtils"

export const StoreRetailCampaignPricesTable = (props: StoreRetailPricesTableProps) => {
    const { t } = useTranslation()
    const { prices } = props
    if (!prices) {
        return null
    } else if (prices.length === 0) {
        return <p>{t("Prices.noCampaignPrices")}</p>
    }
    const nonEmptyCols = getNonEmptyColumns(prices)
    // Hide pricing unit if it is 1 in every row
    if (allPricingUnitsEqual1(prices)) {
        nonEmptyCols.delete("pricingUnit")
    }
    const sitesMap = siteArrayToMap(props.sites)
    return (
        <div className={"table-wrapper"}>
            <p>{t("Prices.storeRetailCampaignPricesHelperText")}</p>
            <table>
                <thead>
                    <tr>
                        <th>{t("Common.siteId")}</th>
                        <th>{t("Common.siteName")}</th>
                        <th>{t("Prices.amount")}</th>
                        <th>{t("Common.validFrom")}</th>
                        <th>{t("Common.validTo")}</th>
                        {/* Following words are quite long in Finnish, and we want to use the HTML code for soft
                        hyphen &shy; and it needs to be escaped for React AND i18next library. */}
                        <th
                            dangerouslySetInnerHTML={{
                                __html: t("Prices.salesUnitWithSoftHyphen", {
                                    interpolation: { escapeValue: false }
                                })
                            }}
                        />
                        {nonEmptyCols.has("pricingUnit") && (
                            <th
                                dangerouslySetInnerHTML={{
                                    __html: t("Prices.pricingUnitWithSoftHyphen", {
                                        interpolation: {
                                            escapeValue: false
                                        }
                                    })
                                }}
                            />
                        )}
                        <th>{t("Prices.promotionId")}</th>
                        {nonEmptyCols.has("promotionType") && <th>{t("Prices.promotionType")}</th>}
                        {nonEmptyCols.has("promotionCustomerType") && <th>{t("Prices.promotionCustomerType")}</th>}
                        <th>{t("Prices.pricingLevel")}</th>
                        {nonEmptyCols.has("chainPriceMinAmount") && <th>{t("Prices.chainPriceMin")}</th>}
                        {nonEmptyCols.has("sitePriceMinAmount") && <th>{t("Prices.sitePriceMin")}</th>}
                        {nonEmptyCols.has("deposits") && <th>{t("Prices.deposits")}</th>}
                        <th>{t("Common.modifiedAt")}</th>
                    </tr>
                </thead>
                <tbody>
                    {prices.map((p: any) => {
                        const site = sitesMap.get(p.siteId)
                        const siteName = site ? site.siteName : ""

                        const chainPriceMin = getPriceMinText(
                            p.pricingUnit,
                            p.salesUnit,
                            p.chainPriceMinAmount,
                            p.chainPriceMinPricingUnit,
                            p.chainPriceMinSalesUnit
                        )
                        const sitePriceMin = getPriceMinText(
                            p.pricingUnit,
                            p.salesUnit,
                            p.sitePriceMinAmount,
                            p.sitePriceMinPricingUnit,
                            p.sitePriceMinSalesUnit
                        )

                        return (
                            <tr key={"store-retail-campaign-price-" + p.id} title={"ID: " + p.id}>
                                <td>{p.siteId}</td>
                                <td>{siteName}</td>
                                <td>{p.amount}</td>
                                <td>{p.validFrom}</td>
                                <td>{p.validTo}</td>
                                <td>{p.salesUnit}</td>
                                {nonEmptyCols.has("pricingUnit") && <td>{p.pricingUnit}</td>}
                                <td>{p.promotionId ? p.promotionId : ""}</td>
                                {nonEmptyCols.has("promotionType") && <td>{p.promotionType}</td>}
                                {nonEmptyCols.has("promotionCustomerType") && <td>{p.promotionCustomerType}</td>}
                                <td>{p.promotionPricingLevel}</td>
                                {nonEmptyCols.has("chainPriceMinAmount") && <td>{chainPriceMin}</td>}
                                {nonEmptyCols.has("sitePriceMinAmount") && <td>{sitePriceMin}</td>}
                                {nonEmptyCols.has("deposits") && (
                                    <td>
                                        <DepositList deposits={p.deposits} />
                                    </td>
                                )}
                                <td>{formatTimestamp(p.sourceModifiedAt)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

function getPriceMinText(
    pricingUnit: string,
    salesUnit: string,
    priceMinAmount?: number,
    priceMinPricingUnit?: string,
    priceMinSalesUnit?: string
): string {
    let priceMinText: string = ""
    if (priceMinAmount) {
        priceMinText = priceMinAmount.toString()
        if (priceMinPricingUnit !== pricingUnit || priceMinSalesUnit !== salesUnit) {
            priceMinText += ` (${priceMinPricingUnit}x${priceMinSalesUnit})`
        }
    }
    return priceMinText
}
